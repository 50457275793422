








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import UserDetailTemplate from '@/components/users/UserDetailTemplate.vue';
import { UpdateUserForAxios } from '@/models/users';
import { UserStores } from '@/store/users';

@Component({
  components: { Page, BackButtonLayout, UserDetailTemplate },
})
export default class ViewProfile extends Vue {

    userProfileStore = UserStores.profile;

    get layoutTitle() {
        return `Your profile`;
    }

    get currentProfile(): Partial<UpdateUserForAxios> { 
        return this.userProfileStore.profileDetails;
    }

}
